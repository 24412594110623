import { createRef, useEffect, useRef } from "react";
import * as THREE from "three";
import { Tween, Easing, removeAll, update } from "@tweenjs/tween.js";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls.js";
import {
  CSS3DRenderer,
  CSS3DObject,
} from "three/examples/jsm/renderers/CSS3DRenderer.js";
import { WeightedItem } from "./weighted-list";

type Props = {
  items: any[];
  winner: WeightedItem;
  resultDate: Date | undefined;
};

export const ThreeSphere = (props: Props) => {
  const ref: any = createRef();

  const table: any[] = props.items;

  let camera: any, scene: any, renderer: any;
  let controls;
  let winnerObject: any = useRef();

  const objects: any[] = [];
  const targets = {
    table: [] as any,
    sphere: [] as any[],
    helix: [],
    grid: [] as any[],
    end: [] as any[],
  };

  const maxRepetitions = 10;
  let repetitionsCounter = 0;
  let runningTween: any;

  useEffect(() => {
    init();
    animate();
    //let demo = interv();
    console.log(ref.current);
    winnerObject.current = createWinner(props.winner.label);
  });

  const init = () => {
    camera = new THREE.PerspectiveCamera(
      40,
      ref.current.offsetWidth / ref.current.offsetHeight,
      1,
      10000
    );
    camera.position.z = 3000;

    scene = new THREE.Scene();

    for (let i: number = 0; i < table.length; i += 5) {
      /*
      const element = document.createElement("div");
      element.className = "element";
      element.style.backgroundColor =
        "rgba(245,66,209," + (Math.random() * 0.5 + 0.25) + ")";

      const number = document.createElement("div");
      number.className = "number";
      number.textContent = (i / 5 + 1).toString(10);
      element.appendChild(number);

      const symbol = document.createElement("div");
      symbol.className = "symbol";
      symbol.textContent = table[i].toString(10);
      element.appendChild(symbol);

      const details = document.createElement("div");
      details.className = "details";
      details.innerHTML = table[i + 1] + "<br>" + table[i + 2];
      element.appendChild(details);

      const objectCSS = new CSS3DObject(element);
      objectCSS.position.x = Math.random() * 4000 - 2000;
      objectCSS.position.y = Math.random() * 4000 - 2000;
      objectCSS.position.z = Math.random() * 4000 - 2000;

      */

      const objectCSS = createObject(table[i], table[i + 1]);
      scene.add(objectCSS);

      objects.push(objectCSS);

      //

      const object = new THREE.Object3D();
      object.position.x = +table[i + 3] * 140 - 1330;
      object.position.y = -(+table[i + 4] * 180) + 990;

      targets.table.push(object);
    }

    // sphere

    const vector = new THREE.Vector3();

    for (let i = 0, l = objects.length; i < l; i++) {
      const phi = Math.acos(-1 + (2 * i) / l);
      const theta = Math.sqrt(l * Math.PI) * phi;

      const object = new THREE.Object3D();
      object.position.setFromSphericalCoords(800, phi, theta);
      vector.copy(object.position).multiplyScalar(2);
      object.lookAt(vector);
      targets.sphere.push(object);
    }

    // grid

    for (let i = 0; i < objects.length; i++) {
      const object = new THREE.Object3D();

      object.position.x = (i % 5) * 400 - 800;
      object.position.y = -(Math.floor(i / 5) % 5) * 400 + 800;
      object.position.z = Math.floor(i / 25) * 25;// - 2000;

      targets.grid.push(object);
    }

    // end

    for (let i = 0; i < objects.length; i++) {
      const object = new THREE.Object3D();

      object.position.x = (i % 5) * 400 - 800;
      object.position.y = -(Math.floor(i / 5) % 5) * 400 + 800;
      object.position.z = 3000;

      targets.end.push(object);
    }

    renderer = new CSS3DRenderer();
    renderer.setSize(ref.current.offsetWidth, ref.current.offsetHeight);
    ref.current.appendChild(renderer.domElement);

    controls = new TrackballControls(camera, renderer.domElement);
    controls.minDistance = 500;
    controls.maxDistance = 6000;
    controls.addEventListener("change", render);
    //document.getElementById("container").appendChild(renderer.domElement);
    transform(targets.sphere, 1000);

    window.addEventListener("resize", onWindowResize);
  };

  const createWinner = (name: string) => {
    const objectCSS = createWinnerObject(name);
    objectCSS.element.classList.add("winner");
    objectCSS.position.z = 3000;
    console.log(objectCSS);
    return objectCSS;
  };

  const createWinnerObject = (name: string) => {
    const element = document.createElement("div");
    element.className = "winner";
    const subElement = document.createElement("div");
    subElement.textContent = name;
    element.appendChild(subElement);

    const objectCSS = new CSS3DObject(element);
    return objectCSS;
  };

  const createObject = (line1: string, line2: string) => {
    console.log([line1, line2]);
    const element = document.createElement("div");
    element.className = "element";
    element.style.backgroundColor = "rgba(65,130,151,.9)";
    //"rgba(65,130,151," + (Math.random() * 0.9 + 0.25) + ")";

    /*
      const number = document.createElement("div");
      number.className = "number";
      number.textContent = (i / 5 + 1).toString(10);
      element.appendChild(number);
      */

    const symbol = document.createElement("div");
    symbol.className = "symbol";
    //symbol.textContent = table[i].toString(10);
    symbol.textContent = line1;
    element.appendChild(symbol);

    const details = document.createElement("div");
    details.className = "details";
    //details.innerHTML = table[i + 1] + "<br>" + table[i + 2];
    details.innerHTML = line2;
    element.appendChild(details);

    const objectCSS = new CSS3DObject(element);
    objectCSS.position.x = Math.random() * 4000 - 8000;
    objectCSS.position.y = Math.random() * 4000 - 2000;
    objectCSS.position.z = Math.random() * 4000 - 2000;

    return objectCSS;
  };

  const transform = (targets: any, duration: any) => {
    removeAll();

    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      const target = targets[i];

      new Tween(object.position)
        .to(
          { x: target.position.x, y: target.position.y, z: target.position.z },
          Math.random() * duration + duration
        )
        .easing(Easing.Exponential.InOut)
        .start();

      new Tween(object.rotation)
        .to(
          { x: target.rotation.x, y: target.rotation.y, z: target.rotation.z },
          Math.random() * duration + duration
        )
        .easing(Easing.Exponential.InOut)
        .start();
    }

    runningTween = new Tween({})
      .to({}, duration * 2)
      .onUpdate(render)
      .start()
      .onComplete(function () {
        console.log(repetitionsCounter);
        if (repetitionsCounter < maxRepetitions) {
          if (repetitionsCounter % 2 === 0) {
            shuffleSphere();
            //shuffleGrid();
          } else {
            shuffleGrid();
          }
        } else if (repetitionsCounter === maxRepetitions) {
          runningTween.stop();
          ending();
        } else if (repetitionsCounter === maxRepetitions + 1) {
          showWinner();
        }
        repetitionsCounter++;
      });
  };

  const animate = () => {
    requestAnimationFrame(animate);
    update();
  };

  const render = () => {
    //console.log(scene, camera);
    renderer.render(scene, camera);
  };

  const showWinner = () => {
    console.log("in showWinner");
    scene.clear();
    console.log(winnerObject.current);
    scene.add(winnerObject.current);

    const duration = 1000;

    new Tween(winnerObject.current.position)
      .to({ x: 0, y: 0, z: 0 }, Math.random() * duration + duration)
      .easing(Easing.Exponential.InOut)
      .start();

    new Tween({})
      .to({}, duration * 2)
      .onUpdate(render)
      .start();
  };

  const ending = () => {
    transform(targets.end, 1000);
  };

  const shuffle = (arr: any) => {
    let currentIndex = arr.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [arr[currentIndex], arr[randomIndex]] = [
        arr[randomIndex],
        arr[currentIndex],
      ];
    }
    return arr;
  };

  const shuffleSphere = () => {
    const s = shuffle(targets.sphere);
    transform(s, 1000);
  };

  const shuffleGrid = () => {
    console.log('shuffle grid');
    transform(shuffle(targets.grid), 1000);
  };

  const onWindowResize = () => {
    camera.aspect = ref.current.offsetWidth / ref.current.offsetHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(ref.current.offsetWidth, ref.current.offsetHeight);
    render();
  };

  return <div ref={ref} title="the-sphere" className="three-container" />;
};
