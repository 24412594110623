import React, { SyntheticEvent, useState } from "react";
import "./App.css";
import { WeightedItem, WeightedList } from "./components/weighted-list";
import { buildFullList } from "./services/list-builder";
import { randomItem } from "./services/random-item";
//import { WinnerDisplay } from "./components/winner-display";
import { CsvFileImport } from "./components/csv-file-import";
import { shuffle } from "lodash";
import { ThreeSphere } from "./components/three-sphere";

function App() {
  const [winner, setWinner] = useState<WeightedItem>();
  const [items, updateItems] = useState<WeightedItem[]>([]);
  const [itemsForSphere, updateItemForSpheres] = useState<any[]>([]);
  const [duration, setDuration] = useState(5);
  const [resultDate, setResultDate] = useState<Date>();

  const addItem = (item: WeightedItem) => {
    updateItems([item, ...items]);
  };

  const addItems = (itemsToAdd: WeightedItem[]) => {
    updateItems([...itemsToAdd, ...items]);
  };

  const removeItem = (itemToRemove: WeightedItem) => {
    updateItems(items.filter((item) => item !== itemToRemove));
  };

  const buildListForSphere = (items:WeightedItem[]) => {
    let newArray: any[] = [];
    for (let item of items) {
      const name = item.label.split(' ')[0];
      const lastName = item.label.split(' ')[1];
      newArray.push(name, lastName, '1', 1, 1);
    }
    return newArray;
  };

  const pickWinner = (e: SyntheticEvent) => {
    e.preventDefault();
    const fullList = buildFullList(items);
    const shuffledList = shuffle(fullList);
    const winner = randomItem(shuffledList);
    //console.log(["winner", winner]);
    updateItemForSpheres(buildListForSphere(shuffledList));
    setWinner(winner);
    setResultDate(new Date());
  };

  const durationInput = (duration: string) => {
    if (duration) {
      setDuration(parseInt(duration));
    }
  };

  return (
    <div className="App">
      <div className="flex">
        <div className="flex flex-col justify-between h-screen bg-white w-4/12 ">
          <div className="overflow-y-auto">
          <WeightedList
            items={items}
            addItem={addItem}
            removeItem={removeItem}
          />
          </div>
          <div>
            <CsvFileImport addItems={addItems} />
          </div>
          <div className="">
            <header className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-gray-200 border-b">
              Parameters
            </header>
            <div className="px-6 py-3">
              <label htmlFor="duration" className="pr-2">Animation duration</label>
              <input
                type="number"
                id="duration"
                value={duration}
                className="text-right w-20 bg-blue-50 rounded px-2 py"
                onChange={(e) => durationInput(e.currentTarget.value)}
                required
              />
              <span className="pl-2">seconds</span>
            </div>
          </div>
        </div>
        <div className="flex-grow bg-image ">
          {!winner && (
            <div className="text-center">
              <button
                onClick={pickWinner}
                className="px-4 py-2 bg-white rounded-md"
              >
                <i className="fas fa-dice mr-3"></i> Pick the winner
              </button>
            </div>
          )}
          {winner && (
            <div className="h-screen">
            <ThreeSphere items={itemsForSphere} winner={winner} resultDate={resultDate}></ThreeSphere>
           </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
