import { v4 as uuidv4 } from "uuid";
import { WeightedItem } from "../components/weighted-list";

export const itemFactory = (label: string, weight: number): WeightedItem => {
  return {
    id: uuidv4(),
    label: label,
    weight: weight,
  };
};
