import React, { useRef, useState } from "react";
import { itemFactory } from "../services/factory";

export interface WeightedItem {
  id: string;
  label: string;
  weight: number;
}

type Props = {
  items: WeightedItem[];
  addItem: (item: WeightedItem) => void;
  removeItem: (item: WeightedItem) => void;
};

export const WeightedList = (props: Props) => {
  // build the table rows with the items.
  const itemElements = props.items.map((item: WeightedItem) => (
    <tr key={item.id} className=" ">
      <td className="px-6 py-3 text-left">{item.label}</td>
      <td className="pl-4 pr-6 py-2 text-right">{item.weight}</td>
      <td className="px-6 py-3">
        <button onClick={(e) => removeItem(item, e)} title="Remove from the list"><i className="far fa-minus-square text-red-600 text-lg"></i></button>
      </td>
    </tr>
  ));

  // handling of the form
  const DEFAULT_WEIGHT: number = 1;
  const [label, setLabel] = useState<string>("");
  const [weight, setWeight] = useState<number>(DEFAULT_WEIGHT);
  const addInput = useRef<HTMLInputElement>(null);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    props.addItem(itemFactory(label, weight));
    resetFormFields();
  };

  const resetFormFields = () => {
    setLabel("");
    setWeight(DEFAULT_WEIGHT);
    addInput.current?.focus();
  };

  const removeItem = (item: WeightedItem, e: React.SyntheticEvent) => {
    //console.log(["remove", item]);
    e.preventDefault();
    props.removeItem(item);
  };

  const weightInput = (weight: string) => {
    if (weight) {
      setWeight(parseInt(weight));
    }
  };

  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr className="">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
              <th className="pl-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Number of chances</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr className="">
              <td className="px-6 py-3 whitespace-nowrap">
                <input
                  type="text"
                  placeholder="ex: John Doe"
                  value={label}
                  onChange={(e) => setLabel(e.currentTarget.value)}
                  required
                  className="w-full bg-blue-50 rounded px-4 py-2"
                  ref={addInput}
                />
              </td>
              <td className="pl-6 py-3 whitespace-nowrap text-right">
                <input
                  type="number"
                  placeholder="ex: 3"
                  value={weight}
                  onChange={(e) => weightInput(e.currentTarget.value)}
                  required
                  className="text-right w-20 bg-blue-50 rounded px-4 py-2"
                />
              </td>
              <td className="px-6 py-3 ">
                <button title="Add to the list"><i className="fas fa-plus-square text-blue-500 text-lg"></i></button>
              </td>
            </tr>
            {itemElements}
          </tbody>
        </table>
      </form>
    </div>
  );
};
