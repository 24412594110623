import React from "react";
import { parse } from "papaparse";
import { WeightedItem } from "./weighted-list";
import { itemFactory } from "../services/factory";

type Props = {
  addItems: (items: WeightedItem[]) => void;
};

export const CsvFileImport = (props: Props) => {
  /**
   *
   * @param e
   */
  const loadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: any = e.target.files;
    //console.log(files);
    if (files.length > 0) {
      parse(files[0], {
        complete: function (results) {
          const items = results.data.map((row: any) => itemFactory(row[0], row[1]));
          props.addItems(items);
        },
      });
    }
  };

  return (
    <>
      <input type="file" accept=".csv" onChange={(e) => loadFile(e)} />
    </>
  );
};
