import { WeightedItem } from "../components/weighted-list";

export const buildFullList = (listOfItems: WeightedItem[]) => {
  const fullList: WeightedItem[] = [];
  listOfItems.forEach((item: WeightedItem) => {
    for (let i = 0; i < item.weight; i++) {
      fullList.push(item);
    }
  });
  return fullList;
};
